const getDefaultState = () => {
  return {
    tipologiaRaduni: null,
    denominazioneRaduni: null,
    disciplinaRaduni: null,
    comitatoRaduni: null,
    dataInizioDaRaduni: null,
    dataInizioARaduni: null,
    dataFineDaRaduni: null,
    dataFineARaduni: null,
    statoRaduni: null,
    rowsToSkipRaduni: 0,
    fetchRowsRaduni: 25,
    currentPageRaduni: 1,
    sortColumnRaduni: "id_raduno",
    sortOrderRaduni: "desc",
  };
};
const state = getDefaultState();

const getters = {
  tipologiaRaduni: (state) => state.tipologiaRaduni,
  denominazioneRaduni: (state) => state.denominazioneRaduni,
  disciplinaRaduni: (state) => state.disciplinaRaduni,
  comitatoRaduni: (state) => state.comitatoRaduni,
  dataInizioDaRaduni: (state) => state.dataInizioDaRaduni,
  dataInizioARaduni: (state) => state.dataInizioARaduni,
  dataFineDaRaduni: (state) => state.dataFineDaRaduni,
  dataFineARaduni: (state) => state.dataFineARaduni,
  statoRaduni: (state) => state.statoRaduni,
  rowsToSkipRaduni: (state) => state.rowsToSkipRaduni,
  fetchRowsRaduni: (state) => state.fetchRowsRaduni,
  currentPageRaduni: (state) => state.currentPageRaduni,
  sortColumnRaduni: (state) => state.sortColumnRaduni,
  sortOrderRaduni: (state) => state.sortOrderRaduni,
};

// const actions = {};
const mutations = {
  setTipologiaRaduni: (state, value) => {
    state.tipologiaRaduni = value;
  },
  setDenominazioneRaduni: (state, value) => {
    state.denominazioneRaduni = value;
  },
  setDisciplinaRaduni: (state, value) => {
    state.disciplinaRaduni = value;
  },
  setComitatoRaduni: (state, value) => {
    state.comitatoRaduni = value;
  },
  setDataInizioDaRaduni: (state, value) => {
    state.dataInizioDaRaduni = value;
  },
  setDataInizioARaduni: (state, value) => {
    state.dataInizioARaduni = value;
  },
  setDataFineDaRaduni: (state, value) => {
    state.dataFineDaRaduni = value;
  },
  setDataFineARaduni: (state, value) => {
    state.dataFineARaduni = value;
  },
  setStatoRaduni: (state, value) => {
    state.statoRaduni = value;
  },
  setFetchRowsRaduni: (state, value) => {
    state.currentPageRaduni = 1;
    state.rowsToSkipRaduni = 0;
    state.fetchRowsRaduni = value;
  },
  setCurrentPageRaduni: (state, value) => {
    state.currentPageRaduni = value;
    state.rowsToSkipRaduni = parseInt(
      (state.currentPageRaduni - 1) * state.fetchRowsRaduni
    );
  },
  setSortColumnRaduni: (state, value) => {
    state.sortColumnRaduni = value;
  },
  setSortOrderRaduni: (state, value) => {
    state.sortOrderRaduni = value;
  },
  resetFiltersRaduni: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipRaduni: (state) => {
    state.currentPageRaduni = 1;
    state.rowsToSkipRaduni = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
