const getDefaultState = () => {
  return {
    rowsToSkipConfigurazioniRaduni: 0,
    fetchRowsConfigurazioniRaduni: 25,
    currentPageConfigurazioniRaduni: 1,
    sortColumnConfigurazioniRaduni: "id",
    sortOrderConfigurazioniRaduni: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipConfigurazioniRaduni: (state) =>
    state.rowsToSkipConfigurazioniRaduni,
  fetchRowsConfigurazioniRaduni: (state) => state.fetchRowsConfigurazioniRaduni,
  currentPageConfigurazioniRaduni: (state) =>
    state.currentPageConfigurazioniRaduni,
  sortColumnConfigurazioniRaduni: (state) =>
    state.sortColumnConfigurazioniRaduni,
  sortOrderConfigurazioniRaduni: (state) => state.sortOrderConfigurazioniRaduni,
};

// const actions = {};
const mutations = {
  setFetchRowsConfigurazioniRaduni: (state, value) => {
    state.currentPageConfigurazioniRaduni = 1;
    state.rowsToSkipConfigurazioniRaduni = 0;
    state.fetchRowsConfigurazioniRaduni = value;
  },
  setCurrentPageConfigurazioniRaduni: (state, value) => {
    state.currentPageConfigurazioniRaduni = value;
    state.rowsToSkipConfigurazioniRaduni = parseInt(
      (state.currentPageConfigurazioniRaduni - 1) *
        state.fetchRowsConfigurazioniRaduni
    );
  },
  setSortColumnConfigurazioniRaduni: (state, value) => {
    state.sortColumnConfigurazioniRaduni = value;
  },
  setSortOrderConfigurazioniRaduni: (state, value) => {
    state.sortOrderConfigurazioniRaduni = value;
  },
  resetFiltersConfigurazioniRaduni: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
