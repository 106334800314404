const getDefaultState = () => {
  return {
    rowsToSkipRadunoPartecipanti: 0,
    fetchRowsRadunoPartecipanti: 25,
    currentPageRadunoPartecipanti: 1,
    sortColumnRadunoPartecipanti: "cognome",
    sortOrderRadunoPartecipanti: "asc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipRadunoPartecipanti: (state) => state.rowsToSkipRadunoPartecipanti,
  fetchRowsRadunoPartecipanti: (state) => state.fetchRowsRadunoPartecipanti,
  currentPageRadunoPartecipanti: (state) => state.currentPageRadunoPartecipanti,
  sortColumnRadunoPartecipanti: (state) => state.sortColumnRadunoPartecipanti,
  sortOrderRadunoPartecipanti: (state) => state.sortOrderRadunoPartecipanti,
};

// const actions = {};
const mutations = {
  setFetchRowsRadunoPartecipanti: (state, value) => {
    state.currentPageRadunoPartecipanti = 1;
    state.rowsToSkipRadunoPartecipanti = 0;
    state.fetchRowsRadunoPartecipanti = value;
  },
  setCurrentPageRadunoPartecipanti: (state, value) => {
    state.currentPageRadunoPartecipanti = value;
    state.rowsToSkipRadunoPartecipanti = parseInt(
      (state.currentPageRadunoPartecipanti - 1) *
        state.fetchRowsRadunoPartecipanti
    );
  },
  setSortColumnRadunoPartecipanti: (state, value) => {
    state.sortColumnRadunoPartecipanti = value;
  },
  setSortOrderRadunoPartecipanti: (state, value) => {
    state.sortOrderRadunoPartecipanti = value;
  },
  resetFiltersRadunoPartecipanti: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
