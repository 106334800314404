const getDefaultState = () => {
  return {
    stagione_precRinnovo: new Date().getFullYear() - 1,
    stagione_rinnRinnovo: new Date().getFullYear(),
    id_nazioneRinnovo: null,
    id_nazioni_genericheRinnovo: null,
    cittadinanzaRinnovo: null,
    estivaRinnovo: null,
    consiglioRinnovo: false,
    disabileRinnovo: null,

    cognomeRinnovo: "",
    data_emissione_daRinnovo: null,
    data_emissione_aRinnovo: null,
    codiceTesseraRinnovo: null,
    certificatoRinnovo: null,
    id_tipo_tesseraRinnovo: null,
    id_disciplinaRinnovo: null,
    rowsToSkipRinnovo: 0,
    fetchRowsRinnovo: 25,
    currentPageRinnovo: 1,
    sortColumnRinnovo: "id",
    sortOrderRinnovo: "asc",
    id_categoriaRinnovo: null,
    id_qualificaRinnovo: null,
  };
};
const state = getDefaultState();

const getters = {
  cognomeRinnovo: (state) => state.cognomeRinnovo,
  data_emissione_daRinnovo: (state) => state.data_emissione_daRinnovo,
  data_emissione_aRinnovo: (state) => state.data_emissione_aRinnovo,
  codiceTesseraRinnovo: (state) => state.codiceTesseraRinnovo,
  certificatoRinnovo: (state) => state.certificatoRinnovo,
  id_tipo_tesseraRinnovo: (state) => state.id_tipo_tesseraRinnovo,
  id_disciplinaRinnovo: (state) => state.id_disciplinaRinnovo,
  rowsToSkipRinnovo: (state) => state.rowsToSkipRinnovo,
  fetchRowsRinnovo: (state) => state.fetchRowsRinnovo,
  currentPageRinnovo: (state) => state.currentPageRinnovo,
  sortColumnRinnovo: (state) => state.sortColumnRinnovo,
  sortOrderRinnovo: (state) => state.sortOrderRinnovo,
  id_categoriaRinnovo: (state) => state.id_categoriaRinnovo,
  id_qualificaRinnovo: (state) => state.id_qualificaRinnovo,
  stagione_precRinnovo: (state) => state.stagione_precRinnovo,
  stagione_rinnRinnovo: (state) => state.stagione_rinnRinnovo,
  id_nazioneRinnovo: (state) => state.id_nazioneRinnovo,
  id_nazioni_genericheRinnovo: (state) => state.id_nazioni_genericheRinnovo,
  cittadinanzaRinnovo: (state) => state.cittadinanzaRinnovo,
  estivaRinnovo: (state) => state.estivaRinnovo,
  consiglioRinnovo: (state) => state.consiglioRinnovo,
  disabileRinnovo: (state) => state.disabileRinnovo,
};

// const actions = {};
const mutations = {
  setCognomeRinnovo: (state, value) => {
    state.cognomeRinnovo = value;
  },
  setdata_emissione_daRinnovo: (state, value) => {
    state.data_emissione_daRinnovo = value;
  },
  setdata_emissione_aRinnovo: (state, value) => {
    state.data_emissione_aRinnovo = value;
  },
  setCodiceTesseraRinnovo: (state, value) => {
    state.codiceTesseraRinnovo = value;
  },
  setCertificato: (state, value) => {
    state.certificatoRinnovo = value;
  },
  setTipoTesseraRinnovo: (state, value) => {
    state.id_tipo_tesseraRinnovo = value;
  },
  setSettoreRinnovo: (state, value) => {
    state.id_disciplinaRinnovo = value;
  },
  setFetchRowsRinnovo: (state, value) => {
    state.currentPageRinnovo = 1;
    state.rowsToSkipRinnovo = 0;
    state.fetchRowsRinnovo = value;
  },
  setCurrentPageRinnovo: (state, value) => {
    state.currentPageRinnovo = value;
    state.rowsToSkipRinnovo = parseInt(
      (state.currentPageRinnovo - 1) * state.fetchRowsRinnovo
    );
  },
  setSortColumnRinnovo: (state, value) => {
    state.sortColumnRinnovo = value;
  },
  setSortOrderRinnovo: (state, value) => {
    state.sortOrderRinnovo = value;
  },
  setStagionePrec: (state, value) => {
    state.stagione_precRinnovo = value;
  },
  setStagioneRinn: (state, value) => {
    state.stagione_rinnRinnovo = value;
  },
  setDisabileRinnovo: (state, value) => {
    state.disabileRinnovo = value;
  },
  setCategoriaTesseraRinnovo: (state, value) => {
    state.id_categoriaRinnovo = value;
  },
  setEstivaRinnovo: (state, value) => {
    state.estivaRinnovo = value;
  },
  setConsiglioRinnovo: (state, value) => {
    state.consiglioRinnovo = value;
  },
  setCittadinanzaRinnovo: (state, value) => {
    state.cittadinanzaRinnovo = value;
  },
  setNazioneNascitaRinnovo: (state, value) => {
    state.id_nazioneRinnovo = value;
  },
  setNazioniGenericheRinnovo: (state, value) => {
    state.id_nazioni_genericheRinnovo = value;
  },
  setQualificaRinnovo: (state, value) => {
    state.id_qualificaRinnovo = value;
  },
  setDisciplinaRinnovo: (state, value) => {
    state.id_disciplinaRinnovo = value;
  },
  resetFiltersRinnovo: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipRinnovo: (state) => {
    state.currentPageRinnovo = 1;
    state.rowsToSkipRinnovo = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
