import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

//default tema
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
//bacheca
import FiltriBacheca from "@/store/modules/FiltriBacheca";
//comitati
import FiltriComitati from "@/store/modules/FiltriComitati";
import FiltriUtentiUnComitato from "@/store/modules/FiltriUtentiUnComitato";
//Persone
import PersoneList from "@/store/modules/PersoneList.js";
import FiltriDocPersona from "@/store/modules/FiltriDocPersona.js";
// Lookup persone
import LookUpPersone from "@/store/modules/LookUpPersone.js";
// store dinamico
import StoreDinamico from "@/store/modules/StoreDinamico.js";
// Lookup societa
import LookUpRegProvCom from "@/store/modules/LookUpRegProvCom.js";
//Impianti
import ImpiantiList from "@/store/modules/ImpiantiList.js";
import FiltriAssegnaImpiantoList from "@/store/modules/FiltriAssegnaImpiantoList.js";
import FiltriOmologazioniList from "@/store/modules/FiltriOmologazioniList.js";
//Omologazioni
import FiltriOmologazioniRichieste from "@/store/modules/FiltriOmologazioniRichieste";
import FiltriOmologazioniInCarico from "@/store/modules/FiltriOmologazioniInCarico";
import FiltriOmologazioniInAttesa from "@/store/modules/FiltriOmologazioniInAttesa";
import FiltriOmologazioniEsito from "@/store/modules/FiltriOmologazioniEsito";
import FiltriOmologazioniApprovate from "@/store/modules/FiltriOmologazioniApprovate";
import FiltriOmologazioniRifiutate from "@/store/modules/FiltriOmologazioniRifiutate";
import FiltriOmologazioniBozze from "@/store/modules/FiltriOmologazioniBozze.js";

//Affiliazioni secondarie
import FiltriAffiliazioniSecondarie from "@/store/modules/FiltriAffiliazioniSecondarie.js";
//Società
import SocietyList from "@/store/modules/SocietaList.js";
import FiltriEconomatoUnaSocieta from "@/store/modules/FiltriEconomatoUnaSocieta.js";
import FiltriSanzioniUnaSocieta from "@/store/modules/FiltriSanzioniUnaSocieta.js";
import FiltriRichiesteUnaSocieta from "@/store/modules/FiltriRichiesteUnaSocieta.js";
import FiltriPrivacyTesSoc from "@/store/modules/FiltriPrivacyTesSoc.js";
import FiltriTrasferimentiUnaSoc from "@/store/modules/FiltriTrasferimentiUnaSoc.js";
import FiltriTesseratiUnaSocieta from "@/store/modules/FiltriTesseratiUnaSocieta.js";
import FiltriRinnoviSocieta from "@/store/modules/FiltriRinnoviSocieta.js";
import FiltriDocUnaSocieta from "@/store/modules/FiltriDocUnaSocieta.js";
//Menu
import Menu from "@/store/modules/menu.js";
//CommonLookUps
import LookupStagione from "@/store/modules/LookupStagione.js";

import FiltriTesseratiFederali from "@/store/modules/FiltriTesseratiFederali.js";

//ranking
import FiltriRankingList from "@/store/modules/FiltriRankingList.js";
//albo
import FiltriAlboList from "@/store/modules/FiltriAlboList.js";
//albo
import FiltriAlboSNM from "@/store/modules/FiltriAlboSNM.js";
//snm
import FiltriCorsiList from "@/store/modules/FiltriCorsiList.js";
import FiltriRaduniList from "@/store/modules/FiltriRaduniList.js";
import FiltriCorsiPartecipantiList from "@/store/modules/FiltriCorsiPartecipantiList.js";
import FiltriRaduniPartecipantiList from "@/store/modules/FiltriRaduniPartecipantiList.js";
import FiltriConfigurazioniRaduni from "@/store/modules/FiltriConfigurazioniRaduni.js";
import FiltriEconomatoPersonale from "@/store/modules/FiltriEconomatoPersonale";
import FiltriCorsiAreaInsegnante from "@/store/modules/FiltriCorsiAreaInsegnante";
import FiltriAlboInsegnante from "@/store/modules/FiltriAlboInsegnante";

//sanzioni
import FiltriSanzioniList from "@/store/modules/FiltriSanzioniList.js";

//scuole
import FiltriScuoleList from "@/store/modules/FiltriScuoleList";

//tesserati
import FiltriTesseratiSocieta from "@/store/modules/FiltriTesseratiSocieta.js";
import FiltriTrasferimentiList from "@/store/modules/FiltriTrasferimentiList.js";
import FiltriTrasferimentiRichiestwList from "@/store/modules/FiltriTrasferimentiRichiesteList.js";
import FiltriVivaio from "@/store/modules/FiltriVivaio.js";

//economato
import FiltriAffiliazioniEconomato from "@/store/modules/FiltriAffiliazioniEconomato.js";
import FiltriTesseramentiEconomato from "@/store/modules/FiltriTesseramentiEconomato.js";
import FiltriPagamentiEconomato from "@/store/modules/FiltriPagamentiEconomato.js";
import FiltriMovimentiEconomato from "@/store/modules/FiltriMovimentiEconomato.js";
import FiltriSanzioniEconomato from "@/store/modules/FiltriSanzioniEconomato.js";
import FiltriTessereSpecialiEconomato from "@/store/modules/FiltriTessereSpecialiEconomato.js";
import FiltriTessereGoldEconomato from "@/store/modules/FiltriTessereGoldEconomato.js";
import FiltriSaldoEconomato from "@/store/modules/FiltriSaldoEconomato.js";
import FiltriCompensazioniEconomato from "@/store/modules/FiltriCompensazioniEconomato.js";

//utenti
import FiltriSocietaUsers from "@/store/modules/FiltriSocietaUsers.js";
import FiltriPersoneUsers from "@/store/modules/FiltriPersoneUsers.js";
import FiltriComitatiUsers from "@/store/modules/FiltriComitatiUsers.js";
import FiltriOmologatoriUsers from "@/store/modules/FiltriOmologatoriUsers.js";

//esportazioni
import FiltriEsportazioni from "@/store/modules/FiltriEsportazioni.js";

//importazioni
import FiltriImportazioni from "@/store/modules/FiltriImportazioni.js";
import FiltriDettaglioImportazione from "@/store/modules/FiltriDettaglioImportazioni.js";

//pretesseramento
import FiltriHpAnagrafiche from "@/store/modules/FiltriHpAnagrafiche.js";
import FiltriHpRichieste from "@/store/modules/FiltriHpRichieste.js";
import FiltriReportPretesseramento from "@/store/modules/FiltriReportPretesseramento";

//fit-report
import FiltriReports from "@/store/modules/FiltriReports.js";

//report
import FiltriGestioneQuote from "@/store/modules/FiltriGestioneQuote";

//altro
import OldLogin from "@/store/modules/OldLogin.js";
import CampiLocked from "@/store/modules/CampiLockedReadOnly";
import token from "@/store/modules/token";
import UserCreate from "@/store/modules/UserCreate";

import Manutenzione from "@/store/modules/storeManutenzione";

import NumberPendingRequests from "@/store/modules/numberPendingRequestsStore";

//raas
import FiltriRaasAffiliazioni from "@/store/modules/FiltriRaasAffiliazioni";
import FiltriRaasConsigli from "@/store/modules/FiltriRaasConsigli";
import FiltriRaasDidattica from "@/store/modules/FiltriRaasDidattica";
import FiltriRaasDocumenti from "@/store/modules/FiltriRaasDocumenti";
import FiltriRaasFormazione from "@/store/modules/FiltriRaasFormazione";
import FiltriRaasSport from "@/store/modules/FiltriRaasSport";
import FiltriRaasTesseramenti from "@/store/modules/FiltriRaasTesseramenti";

// snm corsi attestati
import FiltriCorsiGeneraAttestatiList from "@/store/modules/FiltriCorsiGeneraAttestatiList";

import FiltriAttestatiAlbo from "@/store/modules/FiltriAttestatiAlbo";
import FiltriAttestatiQualifiche from "@/store/modules/FiltriAttestatiQualifiche";

import EntiAggregatiList from "@/store/modules/EntiAggregatiList";

import FiltriAssegnaImpiantoListEntiAggregati from "@/store/modules/FiltriAssegnaImpiantoListEntiAggregati";

import FiltriDocUnoEnte from "@/store/modules/FiltriDocUnoEnte.js";

import FiltriPrivacyTesEnte from "@/store/modules/FiltriPrivacyTesEnte.js";
import FiltriTesseratiUnoEnte from "@/store/modules/FiltriTesseratiUnoEnte.js";
import FiltriRinnoviEntiAggregati from "@/store/modules/FiltriRinnoviEntiAggregati.js";
import FiltriEconomatoUnoEnte from "@/store/modules/FiltriEconomatoUnoEnte.js";
import FiltriTrasferimentiUnoEnte from "@/store/modules/FiltriTrasferimentiUnoEnte.js";
import FiltriSanzioniUnoEnte from "@/store/modules/FiltriSanzioniUnoEnte.js";
import FiltriVerificheAnomalie from "@/store/modules/FiltriVerificheAnomalie.js";
import FiltriRichiestaImportazioni from "@/store/modules/FiltriRichiestaImportazioni.js";
import FiltriListaCampiPickleballImpiantoList from "@/store/modules/FiltriListaCampiPickleballImpiantoList.js";
import FiltriGestioneRichiesteEventi from "@/store/modules/FiltriGestioneRichiesteEventi.js";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    FiltriBacheca,
    LookupStagione,
    PersoneList,
    ImpiantiList,
    LookUpPersone,
    LookUpRegProvCom,
    SocietyList,
    Menu,
    StoreDinamico,
    FiltriAttestatiAlbo,
    FiltriAttestatiQualifiche,
    FiltriCorsiGeneraAttestatiList,
    FiltriTesseratiSocieta,
    FiltriTesseratiFederali,
    FiltriTesseratiUnaSocieta,
    FiltriVivaio,
    FiltriRinnoviSocieta,
    FiltriRankingList,
    FiltriCorsiList,
    FiltriRaduniList,
    FiltriConfigurazioniRaduni,
    FiltriCorsiPartecipantiList,
    FiltriRaduniPartecipantiList,
    FiltriCorsiAreaInsegnante,
    FiltriAlboInsegnante,
    FiltriSanzioniList,
    FiltriEconomatoUnaSocieta,
    FiltriSanzioniUnaSocieta,
    FiltriRichiesteUnaSocieta,
    FiltriPrivacyTesSoc,
    FiltriTrasferimentiUnaSoc,
    FiltriTrasferimentiList,
    FiltriTrasferimentiRichiestwList,
    FiltriAssegnaImpiantoList,
    FiltriAlboList,
    FiltriAlboSNM,
    FiltriDocUnaSocieta,
    FiltriOmologazioniList,
    FiltriAffiliazioniEconomato,
    FiltriTesseramentiEconomato,
    FiltriPagamentiEconomato,
    FiltriMovimentiEconomato,
    FiltriSanzioniEconomato,
    FiltriScuoleList,
    FiltriTessereSpecialiEconomato,
    FiltriTessereGoldEconomato,
    FiltriSaldoEconomato,
    FiltriCompensazioniEconomato,
    FiltriSocietaUsers,
    FiltriPersoneUsers,
    FiltriComitatiUsers,
    FiltriOmologatoriUsers,
    FiltriComitati,
    FiltriUtentiUnComitato,
    FiltriEconomatoPersonale,
    FiltriEsportazioni,
    FiltriImportazioni,
    FiltriDettaglioImportazione,
    FiltriHpAnagrafiche,
    FiltriHpRichieste,
    FiltriReports,
    FiltriGestioneQuote,
    OldLogin,
    token,
    CampiLocked,
    UserCreate,
    Manutenzione,
    NumberPendingRequests,
    FiltriReportPretesseramento,
    FiltriDocPersona,
    FiltriRaasAffiliazioni,
    FiltriRaasConsigli,
    FiltriRaasDidattica,
    FiltriRaasDocumenti,
    FiltriRaasFormazione,
    FiltriRaasSport,
    FiltriRaasTesseramenti,
    FiltriOmologazioniRichieste,
    FiltriOmologazioniInCarico,
    FiltriOmologazioniInAttesa,
    FiltriOmologazioniEsito,
    FiltriOmologazioniApprovate,
    FiltriOmologazioniRifiutate,
    FiltriAffiliazioniSecondarie,
    FiltriOmologazioniBozze,
    EntiAggregatiList,
    FiltriAssegnaImpiantoListEntiAggregati,
    FiltriDocUnoEnte,
    FiltriPrivacyTesEnte,
    FiltriTesseratiUnoEnte,
    FiltriRinnoviEntiAggregati,
    FiltriEconomatoUnoEnte,
    FiltriTrasferimentiUnoEnte,
    FiltriSanzioniUnoEnte,
    FiltriVerificheAnomalie,
    FiltriRichiestaImportazioni,
    FiltriListaCampiPickleballImpiantoList,
    FiltriGestioneRichiesteEventi,
  },
});

export default store;
